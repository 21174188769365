.root :global(.ant-table-thead .ant-table-cell),
.root :global(.ant-table-thead .ant-table-selection-column) {
  font-size: 0.85em;
  padding: calc(var(--container-gap) / 2) var(--gap-lg);
}

.root_clickable :global(.ant-table-tbody .ant-table-row) {
  cursor: pointer;
}

.root :global(.ant-table-tbody .ant-table-row:nth-child(even)) {
  background-color: var(--color-gray-100);
}

.root :global(.ant-table-tbody .ant-table-cell),
.root :global(.ant-table-tbody .ant-table-selection-column) {
  height: 72px;
  padding: var(--gap-md) var(--gap-lg);
}

.root :global(.ant-table-thead .ant-table-selection-column:first-child),
.root :global(.ant-table-tbody .ant-table-selection-column:first-child) {
  padding-right: 0;
}

.root :global(.ant-pagination) {
  margin: 0 !important;
  padding: calc(var(--container-gap) / 2) var(--container-gap);
}

.root :global(.ant-pagination-item) {
  font-weight: 500;
  border: none;
}

.root :global(.ant-pagination-prev) {
  margin-right: auto;
}

.root :global(.ant-pagination-next) {
  margin-left: auto;
}

.actions {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0s ease 0.3s;
}

.root :global(.ant-table-tbody .ant-table-row:hover) .actions {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.truncate {
  color: inherit;
}

.truncate_small {
  max-width: 240px !important;
}

.truncate_middle {
  max-width: 320px !important;
}
