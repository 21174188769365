td.table__date {
  font-weight: 500;
  white-space: nowrap;
  color: var(--color-dark);
}

td.table__id {
  white-space: nowrap;
}

.details__data {
  font-size: 0.85em;
}
