.item__title {
  font-weight: 600;
}

.divider {
  margin-top: var(--gap-md);
  margin-bottom: var(--gap-md);
}

.divider:last-child {
  display: none;
}
