.root {
  background: var(--color-gray-100);
  padding: calc(var(--container-gap) / 2) var(--container-gap);
}

.panel :global(.ant-form-item) {
  width: auto;
  margin-bottom: 0;
}

.search {
  flex-grow: 1;
  max-width: 320px;
  margin-right: auto;
}
