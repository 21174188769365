.root:global(.ant-btn-primary.ant-btn-filled) {
  background-color: var(--color-primary-lightest);
  color: var(--color-primary);
}

.root:global(.ant-btn-primary.ant-btn-filled):not(:disabled):hover {
  background-color: var(--color-primary-lightest-hover);
}

.root:global(.ant-btn-primary.ant-btn-filled):not(:disabled):active {
  background-color: var(--color-primary-lightest-active);
}

.root svg {
  font-size: var(--icon-size-md);
}

.root:global(.ant-btn-lg) svg {
  font-size: var(--icon-size);
}
