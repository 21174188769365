.root {
  margin-top: var(--container-gap);
  margin-bottom: var(--container-gap);
}

.root:first-child {
  margin-top: 0;
}

.root:last-child {
  margin-bottom: 0;
}
