.root :global(.ant-descriptions-item-container) {
  flex-direction: column;
}

.root :global(.ant-descriptions-item-label) {
  font-weight: 500;
  color: var(--color-gray-500);
}

.root :global(.ant-descriptions-item-label::after) {
  margin-right: 0;
  margin-left: 0;
}

.root :global(.ant-descriptions-item-content) {
  display: initial !important;
  font-weight: 500;
}
