.root {
  max-width: var(--container-width);
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-gap);
  padding-left: var(--container-gap);
}

.root_fluid {
  max-width: 100%;
}
