td.table__name {
  width: 100%;
  font-weight: 500;
  color: var(--color-dark);
}

td.table__date {
  white-space: nowrap;
}

.balance__item {
  white-space: nowrap;
}

.balance__item > span {
  margin-right: 0.25em;
}

.balance__item > strong {
  font-weight: 400;
  color: var(--color-dark);
}
