.divider {
  margin-top: var(--gap-lg);
  margin-bottom: var(--gap-lg);
}

.divider:first-child {
  margin-top: 0;
}

.divider:last-child {
  margin-bottom: 0;
}

.columns + .divider {
  margin-top: var(--gap-sm);
}

.divider :global(.ant-form-item-control-input) {
  min-height: initial;
}

.divider__line {
  margin: 0;
}

.errorList {
  font-size: 0.85em;
  color: var(--color-error);
  padding-top: var(--gap-xs);
}

.item {
  width: 100%;
}

.item :global(.ant-form-item-label) {
  font-weight: 500;
}

.item:global(.ant-form-item-horizontal .ant-form-item-label) {
  margin-right: auto;
  padding-right: var(--gap-xs) !important;
}

.item:global(.ant-form-item-vertical .ant-form-item-label) {
  padding-bottom: var(--gap-xs) !important;
}

.item:global(.ant-form-item-horizontal .ant-form-item-control) {
  flex-basis: auto !important;
  flex-grow: 0;
}

.item :global(.ant-form-item-explain),
.item :global(.ant-form-item-extra) {
  font-size: 0.85em;
  color: var(--color-gray-500);
  padding-top: var(--gap-xs);
}

.item :global(.ant-form-item-margin-offset) {
  display: none;
}

.title:global(.ant-typography) {
  font-size: 16px;
}
