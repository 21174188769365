td.table__action {
  font-weight: 500;
  color: var(--color-dark);
}

td.table__date {
  white-space: nowrap;
}

.modal__diff {
  margin-top: var(--gap-md);
}
