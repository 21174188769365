.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(var(--c-icon-size) * 2);
  height: calc(var(--c-icon-size) * 2);
  background: var(--color-gray-200);
  font-size: var(--c-icon-size);
  vertical-align: middle;
  border-radius: 50%;
  margin-right: var(--gap-sm);
  margin-left: var(--gap-sm);
}

.root:first-child {
  margin-left: 0;
}

.root:last-child {
  margin-right: 0;
}

.root_middle {
  --c-icon-size: calc(var(--icon-size) * 1);
}

.root_large {
  --c-icon-size: calc(var(--icon-size) * 1.2);
}
