:global(.ant-modal-body) .root {
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
  margin-top: calc(var(--container-gap) * -1);
  margin-right: calc(var(--container-gap) * -1);
  margin-left: calc(var(--container-gap) * -1);
}

:global(.ant-modal-body) .actions {
  padding-right: var(--container-gap);
  padding-left: var(--container-gap);
}

.actions__caption {
  margin-left: var(--gap-sm);
}
