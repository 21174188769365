.modal__amount :global(.ant-form-item-control) {
  flex-grow: 1 !important;
  max-width: 200px;
}

.modal__currency {
  width: auto !important;
}

.corridors__label {
  font-weight: 600;
  margin-bottom: var(--gap-sm) !important;
}
