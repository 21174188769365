.info {
  font-size: 0.85em;
  color: var(--color-gray-500);
}

:global(.ant-select .ant-select-selection-item) .info {
  display: none;
}

.info__separator {
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 50%;
}
