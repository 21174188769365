td.table__email {
  font-weight: 500;
  white-space: nowrap;
  color: var(--color-dark);
}

.modal__fee__input,
.modal__fee__checkbox {
  margin-bottom: 0;
}
