td.table__date {
  font-weight: 500;
  white-space: nowrap;
  color: var(--color-dark);
}

td.table__name {
  width: 100%;
}

.modal__fee__input,
.modal__fee__checkbox {
  margin-bottom: 0;
}
