.root {
  position: relative;
}

.root svg {
  font-size: var(--icon-size);
  color: var(--color-gray-500);
}

.root:global(.ant-select-single.ant-select-show-arrow .ant-select-selection-item),
.root:global(.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder) {
  padding-inline-end: calc(var(--gap-xs) + var(--icon-size));
}

.root:global(.ant-select-multiple .ant-select-selection-item) {
  align-items: center;
}

.root:global(.ant-select-multiple .ant-select-selection-item-remove svg) {
  font-size: var(--icon-size-sm);
  transition: color 0.3s ease;
}

.root:global(.ant-select-multiple .ant-select-selection-item-remove:hover svg) {
  color: var(--color-primary);
}

.root :global(.ant-select-arrow) {
  transition: transform 0.3s ease;
}

.root:global(.ant-select-open .ant-select-arrow) {
  transform: rotateX(180deg);
}

.root :global(.ant-select-clear) {
  width: var(--icon-size);
  height: var(--icon-size);
  margin-top: calc(var(--icon-size) / -2);
}

.dropdown:not(.dropdown_sameWidth) {
  max-width: 320px;
}

.ajax:global(.ant-select-open .ant-select-arrow) {
  transform: none;
}
