.root svg {
  font-size: var(--icon-size);
  color: var(--color-gray-500);
}

.root :global(.ant-input-prefix) {
  margin-inline-end: var(--gap-sm);
}

.root :global(.ant-input-suffix) {
  margin-inline-start: var(--gap-sm);
}

.root :global(.ant-input-clear-icon) {
  display: inline-flex;
}

.phone__select {
  width: auto !important;
}

.phone__select :global(.ant-select-selection-item) {
  width: calc(2em + 12px);
  text-overflow: initial;
}

.phone__select__option {
  display: flex;
  align-items: center;
}

.phone__select__code {
  display: none;
}

.phone__select__prefix {
  color: var(--color-gray-500);
  margin-left: var(--gap-sm);
}

:global(.ant-select-selection-item) .phone__select__code {
  display: initial;
}

:global(.ant-select-selection-item) .phone__select__name,
:global(.ant-select-selection-item) .phone__select__prefix {
  display: none;
}

.phone__input {
  margin-left: -1px;
}
