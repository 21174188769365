.header {
  border-bottom: 1px solid var(--color-gray-200);
  position: sticky;
  top: 0;
  z-index: 2;
}

.header__container {
  display: flex;
  align-items: center;
  min-height: 100%;
}

.header__logo {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  width: 100px;
}

.header__menu {
  flex-grow: 1;
  border-bottom: none;
  margin-right: var(--container-gap);
  margin-left: var(--container-gap);
}

.header__menu :global(.ant-menu-item.ant-menu-item-disabled) {
  display: none;
}

.header__menu :global(.ant-menu-title-content) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-sm);
  font-weight: 500;
}

.header__menu :global(.ant-menu-title-content) > a {
  color: var(--color-dark);
}

.header__menu :global(.ant-menu-title-content) > svg {
  font-size: var(--icon-size);
}

.header__menu :global(.ant-menu-submenu.ant-menu-submenu-disabled) {
  display: none;
}

.header__menu:global(.ant-menu-submenu-popup .ant-menu-submenu-title) {
  padding-inline: var(--gap-md) !important;
}

.header__menu :global(.ant-menu-submenu-title > .ant-menu-submenu-arrow) {
  display: none;
}

.header__aside {
  margin-left: auto;
}

.header__aside__button:global(.ant-btn-variant-outlined) {
  border: 2px solid var(--color-primary-lightest);
}

.content {
  display: flex;
  min-height: calc(100vh - var(--header-height));
  padding-top: var(--container-gap);
  padding-bottom: var(--container-gap);
  position: relative;
  z-index: 1;
}

.content__container {
  flex-grow: 1;
  min-height: 100%;
}
