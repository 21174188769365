.root {
  color: var(--color-gray-500);
}

.item > span {
  margin-right: 0.25em;
}

.item > strong {
  font-weight: 500;
  color: var(--color-primary);
  margin-left: 0.25em;
}

.separator {
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 50%;
}
