:root {
  --color-dark: #1d2939;
  --color-light: #ffffff;
  --color-error: #f04438;
  --color-error-lightest: #fcdedc;
  --color-warning: #dc6803;
  --color-success: #12b76a;
  --color-success-lightest: #e0fcef;
  --color-primary: #0073cc;
  --color-primary-hover: #2390d9;
  --color-primary-active: #0058a6;
  --color-primary-lightest: #eff8ff;
  --color-primary-lightest-hover: #e2f2ff;
  --color-primary-lightest-active: #b4deff;
  --color-gray-100: #f9fafb;
  --color-gray-200: #eaeef4;
  --color-gray-300: #d0d5dd;
  --color-gray-400: #a9bbd3;
  --color-gray-500: #667085;
  --color-gray-600: #4b6a94;
  --color-gray-700: #314560;
  --color-gray-800: #2a3c53;
  --color-gray-900: #1d2939;
  --gap-xs: 4px;
  --gap-sm: 8px;
  --gap-md: 16px;
  --gap-lg: 24px;
  --radius: 8px;
  --container-width: 1280px;
  --container-gap: var(--gap-lg);
  --header-height: 64px;
  --icon-size: 20px;
  --icon-size-md: 18px;
  --icon-size-sm: 16px;
  --input-height: 40px;
  --input-padding-x: 11px;
}

body {
  background: var(--color-light);
}

a {
  text-decoration: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
